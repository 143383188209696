<template>
  <div>
    <c-table
      ref="table"
      title="관련 가동전점검 개선사항 목록"
      :columns="grid.columns"
      :gridHeight="setGridHeight"
      :data="moc.prestartupImproves"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="linkClick"
      @innerBtnClicked="innerBtnClicked"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'pre-startup-impr',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        prestartupImproves: [],
        improves: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    height: {
      type: String,
      default: function() {
        return '700px';
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'checkName' },
          { index: 1, colName: 'equipmentName' },
          { index: 2, colName: 'mergeCheck1' },
          { index: 3, colName: 'mergeCheck2' },
          { index: 4, colName: 'mergeCheck2' },
          { index: 5, colName: 'mergeCheck2' },
          { index: 6, colName: 'mergeCheck2' },
          { index: 12, colName: 'mergeCheck2' },
        ],
        columns: [],
        data: [],
        // height: '475px'
      },
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setGridHeight() {
      return this.height ? this.height : 'auto'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.$comm.getComboItems('IBM_CLASS_CD').then(_result1 => {
        this.$comm.getStepItems('IBM_STEP_CD').then(_result2 => {
          this.grid.columns = [
            {
              name: 'checkName', 
              field: 'checkName',
              label: '가동전점검명',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'link'
            },
            {
              name: 'equipmentName', 
              field: 'equipmentName',
              label: '설비',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'link'
            },
            {
              name: 'psrCheckTypeName', 
              field: 'psrCheckTypeName',
              label: '점검유형',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              sortable: false,
              child: [
                {
                  name: 'level',
                  field: 'level',
                  label: '대분류/중분류/소분류',
                  align: 'left',
                  style: 'width:400px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'psrCheckPeriodName',
              field: 'psrCheckPeriodName',
              label: '점검시기',
              align: 'center',
              style: 'width:60px',
              sortable: false,
            },
            {
              name: 'judgmentResultFlag',
              field: 'judgmentResultFlag',
              label: '적합여부',
              align: 'center',
              style: 'width:60px',
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              label: '점검결과',
              align: 'left',
              style: 'width:200px',
              sortable: false,
              innerBtn: true,
              btns: [
                { label: '', icon: 'add', color: 'light-blue', value: '1', tooltip: '개선사항추가' },
                { label: '', icon: 'add', color: 'orange', value: '2', tooltip: '즉시조치사항추가' },
              ],
              disableTarget: 'psrCheckStatusCd',
              disableCon: 'PCSC000001',
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '개선요청 제목',
              align: 'left',
              type: 'link',
              style: 'width:300px',
              sortable: true,
            },
            {
              name: 'ibmClassCd',
              field: 'ibmClassCd',
              label: '개선구분',
              align: 'center',
              style: 'width:100px',
              sortable: true,
              type: 'tag',
              colorItems: _result1
            },
            {
              name: 'ibmStepCd',
              field: 'ibmStepCd',
              label: '진행상태',
              align: 'center',
              style: 'width:100px',
              type: 'tag',
              colorItems: _result2,
              sortable: false
            },
            {
              name: 'improveRequest',
              field: 'improveRequest',
              label: '요청부서정보',
              align: 'center',
              style: 'width:200px',
              sortable: true,
            },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              label: '조치부서',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'remark',
              field: 'remark',
              label: '비고',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
          ]
        });
      });
      // list setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (col.name === 'ibmTitle') {
        if (row.ibmClassCd === 'IC00000001') {
          this.popupOptions.title = '개선';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        } else {
          this.popupOptions.title = '즉시조치';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        }
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
          ibmTaskTypeCd: row.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: row.ibmTaskUnderTypeCd,
        };
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else if (col.name === 'equipmentName') {
        this.popupOptions.title = '설비 상세';
        this.popupOptions.param = row;
        this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityPopup;
      } else {
        this.popupOptions.title = '가동전점검 상세';
        this.popupOptions.param = {
          sopPrestartupCheckId: row ? row.sopPrestartupCheckId : '',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/psr/preStartupReviewDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityPopup;
      }
    },
    closeFacilityPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      if (btn.value === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: props.row.level,
          relationTableKey: props.row.sopPrestartupEquipmentHasCheckItemId,
          ibmTaskTypeCd: 'ITT0000030',
          ibmTaskUnderTypeCd: 'ITTU000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시개선'; // 즉시개선 상세
        this.popupOptions.param = {
          requestContents: props.row.level,
          relationTableKey: props.row.sopPrestartupEquipmentHasCheckItemId,
          ibmTaskTypeCd: 'ITT0000030',
          ibmTaskUnderTypeCd: 'ITTU000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>
